.estatepro__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem;
  width: 100%;

  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 5% 25%,
    rgba(63, 64, 83, 1) 0%,
    rgba(20, 39, 78, 1) 19%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 5% 25%,
    rgba(63, 64, 83, 1) 0%,
    rgba(20, 39, 78, 1) 19%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 5% 25%,
    rgba(63, 64, 83, 1) 0%,
    rgba(20, 39, 78, 1) 19%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 5% 25%,
    rgba(63, 64, 83, 1) 0%,
    rgba(20, 39, 78, 1) 19%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 5% 25%,
    rgba(63, 64, 83, 1) 0%,
    rgba(20, 39, 78, 1) 19%
  );
}

.estatepro__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  color: white;
  font-size: 2rem;
  padding-top: 0.1rem;
  font-family: var(--font-family-bebas);
  letter-spacing: 0.3rem;
}

.estatepro__navbar-links_logo {
  margin-right: 2rem;
}

.estatepro__navbar-links_container {
  display: flex;
  flex-direction: row;
}

.estatepro__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.estatepro__navbar-links_container p,
.estatepro__navbar-sign p,
.estatepro__navbar-menu_container p {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;

  margin: 0 1rem;
  cursor: pointer;
}

.estatepro__navbar-sign button,
.estatepro__navbar-menu_container button {
  padding: 0.8rem 3rem;
  font-family: var(--font-family);
  background: var(--button-bg);
  color: var(--color-text-white);
  font-size: 1rem;
  border-radius: 1rem;
  border: var(--button-bg);
  cursor: pointer;
  line-height: 25px;
  outline: none;
  font-weight: 700;
}
.estatepro__navbar-sign button:hover,
.estatepro__navbar-menu_container button:hover {
  background-color: var(--button-hover);
  color: var(--text-hover);
}

.estatepro__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.estatepro__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  text-align: end;
  background: #14274e;
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.estatepro__navbar-menu_container p {
  margin: 1rem 0;
}

.estatepro__navbar-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 1050px) {
  .estatepro__navbar-links_container {
    display: none;
  }

  .estatepro__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .estatepro__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .estatepro__navbar {
    padding: 2rem;
  }

  .estatepro__navbar-sign {
    display: none;
  }

  .estatepro__navbar-menu_container {
    top: 20px;
  }

  .estatepro__navbar-menu_container-links-sign {
    display: block;
  }
}
