* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-family);
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 3% 25%,
    rgba(22, 49, 114, 1) 0%,
    rgba(30, 86, 160, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 3% 25%,
    rgba(22, 49, 114, 1) 0%,
    rgba(30, 86, 160, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 3% 25%,
    rgba(22, 49, 114, 1) 0%,
    rgba(30, 86, 160, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 3% 25%,
    rgba(22, 49, 114, 1) 0%,
    rgba(30, 86, 160, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 3% 25%,
    rgba(22, 49, 114, 1) 0%,
    rgba(30, 86, 160, 1) 100%
  );
}

.gradient__bg-header {
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 62% 25%,
    rgba(246, 246, 246, 1) 0%,
    rgba(233, 243, 255, 1) 33%,
    rgba(246, 246, 246, 1) 60%,
    rgba(233, 243, 255, 1) 92%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 62% 25%,
    rgba(246, 246, 246, 1) 0%,
    rgba(233, 243, 255, 1) 33%,
    rgba(246, 246, 246, 1) 60%,
    rgba(233, 243, 255, 1) 92%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 62% 25%,
    rgba(246, 246, 246, 1) 0%,
    rgba(233, 243, 255, 1) 33%,
    rgba(246, 246, 246, 1) 60%,
    rgba(233, 243, 255, 1) 92%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 62% 25%,
    rgba(246, 246, 246, 1) 0%,
    rgba(233, 243, 255, 1) 33%,
    rgba(246, 246, 246, 1) 60%,
    rgba(233, 243, 255, 1) 92%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 62% 25%,
    rgba(246, 246, 246, 1) 0%,
    rgba(233, 243, 255, 1) 33%,
    rgba(246, 246, 246, 1) 60%,
    rgba(233, 243, 255, 1) 92%
  );
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blue__text {
  background: var(--gradient-text-blue);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #14274e;
}

.darkorange__text {
  background: var(--gradient-text-darkorange);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px !important;
  line-height: 60px !important;
}

.gradient__textorangewhite {
  background: var(--gradient-text-orange);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section_margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
/* Scale up keyframes */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  .section__margin {
    margin: 4rem 2rem;
  }
}
