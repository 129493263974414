.estatepro__header {
  display: block;
  flex-direction: row;
}

.estatepro__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 5rem;
}

.estatepro__header-content h1 {
  padding-top: 4rem;
  font-family: var(--font-family-bebas);
  letter-spacing: 0.5rem;
  font-weight: 800;
  font-size: 9rem;
  margin-top: 0px;
}

.estatepro__header-content p {
  font-family: var(--font-family);
  text-transform: uppercase;
  font-size: 1.7rem;
  color: var(--color-text-gray);
  margin-top: 0;
  margin-bottom: 2rem;
  padding: 0 6rem 0 6rem;
}

.estatepro__header-content__input {
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-left: 3rem;
  padding-right: 3rem;
}

.estatepro__header-content__input input {
  border-radius: 0.5rem 0 0 0.5rem;
  flex: 3;
  width: 100%;
  max-width: 60vh;
  max-height: 4rem;
  min-height: 4rem;
  font-family: var(--font-family);
  font-size: 18px;
  border: 2px solid var(--color-footer);
  padding: 0 1.5rem;
  outline: none;
  color: var(--color-text-gray);
}

.estatepro__header-content__input button {
  background: var(--button-bg);
  color: var(--color-text-white);
  border-radius: 0 0.5rem 0.5rem 0;
  font-weight: 700;
  flex: 0.1;
  font-size: 18px;
  padding: 0 3rem;
  outline: none;
  cursor: pointer;
  width: 100%;
  max-height: 4rem;
  min-height: 4rem;
  min-width: 2rem;
  font-family: var(--font-family);
  border-left: none;
}
.estatepro__header-content__input button:hover {
  background: var(--button-hover);
  color: var(--text-hover);
}

.estatepro__header img {
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .estatepro__header h1 {
    font-size: 6rem;
    margin-bottom: 0;
  }
  .estatepro__header p {
    font-size: 1.5rem;
    margin-top: 0.1rem;
  }
  .estatepro__header-image {
    top: 18rem;
  }
  .estatepro__header-content__input input {
    max-height: 3rem;
    min-height: 3rem;
    font-size: 16px;
  }
  .estatepro__header-content__input button {
    max-height: 3rem;
    min-height: 2.8rem;
    font-size: 16px;
    min-width: 3rem;
  }

  .estatepro__header {
    flex-direction: column;
  }
  .estatepro__header-content {
    margin: 0 0 3rem;
  }
}
@media screen and (max-width: 1024px) {
  .estatepro__header h1 {
    font-size: 4rem;
    margin-bottom: 0;
  }
  .estatepro__header p {
    font-size: 1.2rem;
    margin-top: 0.1rem;
  }
  .estatepro__header-image {
    top: 18rem;
  }
  .estatepro__header-content__input input {
    max-height: 3rem;
    min-height: 2.5rem;
    font-size: 12px;
  }
  .estatepro__header-content__input button {
    max-height: 3rem;
    min-height: 2.5rem;
    font-size: 12px;
  }

  .estatepro__header {
    flex-direction: column;
  }
  .estatepro__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 1050px) {
  .estatepro__header {
    flex-direction: column;
  }
  .estatepro__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .estatepro__header h1 {
    font-size: 4rem;
    line-height: 60px;
  }
  .estatepro__header p {
    font-size: 1.5rem;
    line-height: 30px;
    margin-top: 1rem;
  }
  .estatepro__header-image {
    top: 17rem;
  }
  .estatepro__header-content__input button {
    font-size: 12px;
    flex: 0.6;
  }
}
@media screen and (max-width: 490px) {
  .estatepro__header h1 {
    font-size: 4rem;
    line-height: 60px;
  }
  .estatepro__header p {
    font-size: 1.5rem;
    line-height: 30px;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 375px) {
  .estatepro__header h1 {
    font-size: 3rem;
    line-height: 60px;
  }
  .estatepro__header p {
    font-size: 1rem;
    padding: 0 30px;
    line-height: 30px;
    margin-top: 1rem;
  }
  .estatepro__header-content__input {
    padding: 0 1.5rem;
  }
}
