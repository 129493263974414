.estatepro__features-container__feature {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;

  margin: 1rem;
}

.estatepro__features-container__feature-title {
  flex: 1;
  max-width: 35rem;
  margin-right: 2rem;
}

.estatepro__features-container__feature-title h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #fff;
}

.estatepro__features-container__feature-title div {
  width: 10rem;
  height: 3px;
  background: var(--gradient-bar);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.25rem;
}

.estatepro__features-container_feature-text {
  flex: 2;
  max-width: 250rem;
  display: flex;
}

.estatepro__features-container_feature-text p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  color: #fff;
}

@media screen and (max-width: 550px) {
  .estatepro__features-container__feature-title h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 2rem 0;
  }

  .estatepro__features-container_feature-text p {
    font-size: 1rem;
    line-height: 20px;
  }

  .estatepro__features-container__feature {
    margin: 1rem 0;
    flex-direction: column;
  }
}
