@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@400;500;600;700;800&display=swap");
:root {
  --font-family: "Poppins", sans-serif;
  --font-family-bebas: "Bebas Neue", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #0f044c 1.84%, #14274e 102.67%);
  --gradient-text-orange: linear-gradient(
    89.97deg,
    #bbbbbb 1.84%,
    #cf9b33 120.67%
  );
  --gradient-text-blue: linear-gradient(
    89.97deg,
    #14274e 1.84%,
    #14274e 120.67%
  );

  --gradient-text-darkorange: linear-gradient(
    89.97deg,
    #4e3b14 1.84%,
    #4e3b14 120.67%
  );

  --gradient-bar: linear-gradient(103.22deg, #14274e -13.86%, #4e3b14 99.55%);

  --color-bg: #f6f6f6;
  --button-bg: #755920;
  --button-bg-blue: #0f044c;

  --button-hover: #394867;
  --text-hover: #fff;

  --color-footer: #14274e;
  --color-blog: #163172;
  --color-text: #0b2447;

  --color-text-gray: gray;
  --color-text-white: #fff;
  --color-text-orange: #eab241;
  --color-text-contrast: #4e3b14;
  --color-subtext: #d6e4f0;
}
