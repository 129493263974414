.estatepro__tools {
  display: flex;
  flex-direction: row;
  background: #cbd0dc;
  margin: 0 8rem;
}

.estatepro__tools-image {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-right: 2rem;
}

.estatepro__tools-image img {
  width: 100%;
  height: 100%;
}

.estatepro__tools-content {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
}

.estatepro__tools-content h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 2rem;
  line-height: 30px;
  color: #4e3b14;
}

.estatepro__tools-content h4:last-child {
  color: #9ba4b4;
}

.estatepro__tools-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 3rem;
  line-height: 45px;
  margin: 1rem 0;
}

.estatepro__tools-content p {
  color: #000;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 30px;

  margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
  .estatepro__tools {
    flex-direction: column;
    margin: 0 2rem;
    border-radius: 2rem;
  }
  .estatepro__tools-content h1 {
    font-size: 3rem;
  }
  .estatepro__tools-content p {
    font-size: 1rem;
  }
  .estatepro__tools-content h4 {
    font-size: 2rem;
  }

  .estatepro__tools-image {
    margin: 1rem 0;
  }

  .estatepro__tools-content {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .estatepro__tools-image img {
    width: 100%;
    height: 100%;
  }
}
