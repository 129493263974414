.estatepro__about {
  display: flex;
  flex-direction: column;

  padding: 2rem;
  background: var(--color-footer);
  margin: 4rem 6rem;

  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 5% 25%,
    rgba(20, 39, 78, 1) 0%,
    rgba(20, 39, 78, 1) 97%,
    rgba(155, 164, 180, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 5% 25%,
    rgba(20, 39, 78, 1) 0%,
    rgba(20, 39, 78, 1) 97%,
    rgba(155, 164, 180, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 5% 25%,
    rgba(20, 39, 78, 1) 0%,
    rgba(20, 39, 78, 1) 97%,
    rgba(155, 164, 180, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 5% 25%,
    rgba(20, 39, 78, 1) 0%,
    rgba(20, 39, 78, 1) 97%,
    rgba(155, 164, 180, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 5% 25%,
    rgba(20, 39, 78, 1) 0%,
    rgba(20, 39, 78, 1) 97%,
    rgba(155, 164, 180, 1) 100%
  );
}

.estatepro__about-feature {
  display: flex;
}

.estatepro__about-feature .estatepro__features-container__feature {
  margin: 0;
}

.estatepro__about-feature .estatepro__features-container__feature-text {
  max-width: 700px;
}
.estatepro__about-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 7rem 0 7rem 2rem;
}

.estatepro__about-heading h1 {
  font-family: var(--font-family-bebas);
  font-weight: 800;
  font-size: 4rem;
  line-height: 4rem;
  max-width: 65rem;
  letter-spacing: 0.1rem;
}

.estatepro__about-heading p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 30px;
  color: var(--color-text-orange);
  cursor: pointer;
  margin-left: 2rem;
}

.estatepro__about-heading p:hover {
  color: #fff;
}

.estatepro__about-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  margin-top: 2rem;
}

.estatepro__about-container .estatepro__features-container__feature {
  flex: 1;
  margin: 1rem;
  min-width: 210px;
  display: unset;
  flex-direction: column;
}

.estatepro__about-container .estatepro__features-container_feature-text {
  margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
  .estatepro__about-heading {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0rem;
  }

  .estatepro__about-heading p {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .estatepro__about-feature .estatepro__features-container__feature {
    flex-direction: column;
  }

  .estatepro__about-feature .estatepro__features-container_feature-text {
    margin-top: 0.5rem;
  }

  .estatepro__about-heading h1 {
    font-size: 28px;
    line-height: 40px;
  }
  .estatepro__about-heading p {
    font-size: 1rem;
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 350px) {
  .estatepro__about-container .estatepro__features-container__feature {
    margin: 1rem 0;
    min-width: 100%;
  }
}
